import toast from "react-hot-toast";
import { Link } from "@inertiajs/react";

const customToastSuccess = (messageObject, options = {}) => {
    toast.custom(
        (t) => (
            <Link
                className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 ${
                    t.visible ? "ring-opacity-100" : "ring-opacity-0"
                }`}
                href={`/incident-form/${messageObject?.id}`}
            >
                <div className="flex-1 w-0 p-4">
                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full dark:bg-green-50">
                        <svg
                            className="w-6 h-6 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>
                    <div className="flex-1 w-full p-4">
                        <div className="text-xl font-medium text-center text-gray-900 dark:text-gray-100">
                            {messageObject?.message}
                        </div>
                    </div>
                </div>
            </Link>
        ),
        options,
    );
};

export default customToastSuccess;
