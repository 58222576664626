const Card = ({
    onClick,
    className = "w-auto text-white rounded-full cursor-pointer bg-sandoval-green",
    titleClassName = "flex align-center gap-2 text-xl leading-7 font-bold",
    titleContClassName = "flex flex-col flex-1 p-[var(--padding-card,2rem)] border border-white gap-2",
    children,
    title,
}) => (
    <button
        title={title}
        rel="button"
        type="button"
        className={className}
        onClick={onClick}
    >
        <div className={titleContClassName}>{children}</div>
    </button>
);

export default Card;
